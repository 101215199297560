/* Provide sufficient contrast against white background */
body {
    font-family: SkodaNext;
    background: #fff;
}
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

#standalone-tool-cta {
    display: none !important;
}
.skoda-164vy0y-Link-root-Results-readMore {
    display: none !important;
}
.skoda-abl67e-Link-root-StationCard-readMore {
    display: none !important;
}
[class$="readMore"] {
    display: none !important;
}
[class$="ConfiguratorBanner-content"] {
    display: none !important;
}
.skoda-3khgqy-ConfiguratorBanner-content {
    display: none !important;
}
.skoda-1fmffum-CtaPanel-root {
    display: none !important;
}
[class$="CtaPanel-root"] {
    display: none !important;
}
.detailPage-link, .detailPage-contacts {
    display: none !important;
}
#factsNav {
    position: fixed;
    right: 0;
    width: 25vw;
}

#facts {
    font-size: 1.2em;
}
    #facts h2 {
        color: #4ba82e;
        font-size: 1.1em;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 2em;
    }
    #facts h3 {
        margin-top: 2em;
        font-size: 1em;
        font-weight: bold;
    }
    #facts p {
        margin-top: 1em;
        margin-bottom: 0;
    }
    #factsNav h2 {
        font-size: 1em;
        margin-top: 1em;
    }
#factsNav a, #factsNavBig a {
    color: #000;
    text-decoration: none;
}
#factsNav {
    background: #c5c6c2;
    border-left: solid 10px #fff;
    overflow: hidden;
    height: 100vh;
}
    #factsNav ul, #factsNavBig ul {
        list-style: square;
    }
    #factsNav, #factsInfo {
        padding: 25px
    }
#factsNavBig {
    font-size: larger;
    background: #d5d6d2;
    overflow: hidden;
    height: 100vh;
    padding: 0 2rem;
}
#factsInfo {
    background: #d5d6d2;
    margin-right: 25vw;
    padding-right: 2em;
    padding-bottom: 100vh;
}
#facts .anch {
    position: relative;
    top: -120px;
}
.factMenu, .factCont {
    position: relative;
}
    .factMenu > *, .factCont > * {
        position: relative;
        z-index: 9;
    }
.active {
}
.active::before {
    content: ' ';
    position: absolute;
    background: white;
    z-index: 7;
}
.factMenu.active::before {
    left: -60px;
    right: -25px;
    top: 0;
    bottom: 0;
}
.factCont.active::before {
    left: -2em;
    right: -2em;
    top: -1em;
    bottom: -1em;
}
.main {
    min-height: 100vh;
    /*min-width: 1920px;*/
}
#home {
    background: #000 url('./assets/images/bcg.png') no-repeat top right;
}
.detail .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #000 url('./assets/images/banner.png') no-repeat top right;
    z-index: 999;
}
.detail .header h1 {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5em;
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
    
    
}
    .detail .header a {
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.5em;
        text-decoration: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        text-align: right;
        padding: 25px;
    }
    .detail .mbody {
        padding-top: 80px;
    }
    ul.menu, ul.menu li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    ul.menu {
        display: flex;
        position: fixed;
        top: 300px;
        left: 0px;
    }
    ul.menu li {
        margin-left: 30px;
    }
.loading {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    width: 100%;
    text-align: center;
}
.extraspace {
    word-spacing: 5px;
}