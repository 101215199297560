/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 25, 2022 */



@font-face {
    font-family: 'SkodaNext';
    src: url('skodanext-black-webfont.woff2') format('woff2'),
         url('skodanext-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;

}




@font-face {
    font-family: 'SkodaNext';
    src: url('skodanext-bold-webfont.woff2') format('woff2'),
         url('skodanext-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'SkodaNext';
    src: url('skodanext-light-webfont.woff2') format('woff2'),
         url('skodanext-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'SkodaNext';
    src: url('skodanext-regular-webfont.woff2') format('woff2'),
         url('skodanext-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}